import SearchOffIcon from "@mui/icons-material/SearchOff";

import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { ASTableV2Cell, ASTableV2Row } from ".";

export const ASTableV2RowEmpty = ({
  gridRow = "2 / last-line",
  children
}: { gridRow?: string } & PropsWithChildren) => {
  const { t } = useTranslation();
  return (
    <ASTableV2Row
      sx={{
        "> td": {
          backgroundColor: "transparent"
        }
      }}
    >
      <ASTableV2Cell
        sx={{
          gridRow,
          gridColumn: "1 / -1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <SearchOffIcon sx={{ fontSize: "8rem", color: "darkGray.light" }} />
        <Typography variant="tableBody" flexGrow={"1"} color="text.secondary">
          {children ?? t("no results")}
        </Typography>
      </ASTableV2Cell>
    </ASTableV2Row>
  );
};
