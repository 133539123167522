import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tab,
  Tabs
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useFilteredVariantInventorySummaries } from "~/hooks/useFilteredVariantInventorySummaries";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import {
  selectOrderCreationSelectedAutostoreGridId,
  selectOrderCreationSelectedPage,
  selectOrderCreationOnlyAvailable,
  selectOrderCreationSimplified
} from "~/redux/selectors/orderCreationSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import { GenerateOrdersPage } from "./GenerateOrdersPage";
import { GeneratePutAwayTasksPage } from "./GeneratePutAwayTasksPage";
import { InventorySummary } from "./InventorySummary";
import { InventoryTable } from "./InventoryTable";
import { InductionTab } from "./OrderCreationInductionTab";
import { PickTab } from "./OrderCreationPickTab";
import { OrderCreationSearch } from "./OrderCreationSearch";
import {
  setOnlyAvailable,
  setSelectedAutostoreGridId,
  setSimplified
} from "./orderCreation.slice";

export type OrderCreationProps = { viewTitle?: ViewNameTranslation };

export function OrderCreation(props: OrderCreationProps) {
  const { viewTitle } = props;

  const { errorToast } = useToast();
  const [tabIndex, setTabIndex] = useState(0);

  const dispatch = useAppDispatch();
  const clientId = useAppSelector(selectUsersClientId);

  if (!clientId) {
    throw new Error("User has no client id configured");
  }

  const onlyAvailable = useAppSelector(selectOrderCreationOnlyAvailable);
  const simplified = useAppSelector(selectOrderCreationSimplified);
  const workstationGridId = useAppSelector(selectWorkstationAutostoreGridId);

  const { data: autostoreGrids } = useGetAutostoreGridsQuery();

  const selectedPage = useAppSelector(selectOrderCreationSelectedPage);
  const selectedAutostoreGridId = useAppSelector(
    selectOrderCreationSelectedAutostoreGridId
  );

  const selectedAutostoreGrid = autostoreGrids?.find(
    (g) => g.autostoreGridId === selectedAutostoreGridId
  );

  const {
    refetch: refetchVariantInventorySummaries,
    error: variantInventorySummariesError
  } = useFilteredVariantInventorySummaries(
    selectedPage,
    selectedAutostoreGridId,
    onlyAvailable
  );

  useEffect(() => {
    dispatch(setSelectedAutostoreGridId(workstationGridId ?? null));
  }, [dispatch, workstationGridId]);

  useEffect(() => {
    if (!variantInventorySummariesError) return;
    errorToast(` ${getMessageFromRtkError(variantInventorySummariesError)}`);
  }, [variantInventorySummariesError, errorToast]);

  useNavbar({
    centerComponent: useMemo(() => <OrderCreationSearch />, []),
    viewTitle
  });

  return (
    <Box
      maxWidth="xl"
      display="grid"
      px={3}
      sx={{
        gridTemplateColumns: "0.8fr 1fr",
        gridTemplateRows: "auto 1fr",
        gridTemplateAreas: `
          "header header"
          "table details"
        `,
        gap: 3
      }}
    >
      <Stack
        gridArea="header"
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Tabs
          value={tabIndex}
          onChange={(_event, newValue) => setTabIndex(newValue as number)}
          aria-label="creation tabs"
        >
          <Tab label="Create Order" />
          <Tab label="Create PutAway" />
          <Tab label="Generate Orders" />
          <Tab label="Generate PutAway" />
        </Tabs>
        <Stack direction="row" gap={3} flexWrap="wrap" alignItems="center">
          {[0, 1].includes(tabIndex) && (
            <>
              <InputLabel htmlFor="simplified">Simplified</InputLabel>
              <Switch
                sx={{ marginLeft: -3 }}
                id={"simplified"}
                name={"simplified"}
                checked={simplified}
                onChange={() => dispatch(setSimplified(!simplified))}
              />
              <InputLabel htmlFor="onlyAvailable">Only Available</InputLabel>
              <Switch
                sx={{ marginLeft: -3 }}
                id="onlyAvailable"
                name="onlyAvailable"
                checked={onlyAvailable}
                onChange={() => dispatch(setOnlyAvailable(!onlyAvailable))}
              />
            </>
          )}

          <Button
            variant="outlined"
            onClick={refetchVariantInventorySummaries}
            color="primary"
          >
            Fetch Inventory
          </Button>

          <Select
            id="selected-grid-for-inventory-search"
            labelId="selected-grid-for-inventory-search"
            value={`${selectedAutostoreGrid?.autostoreGridName || "All Grids"}`}
            onChange={(e: SelectChangeEvent) => {
              const selectedGrid = (autostoreGrids || []).find(
                (grid) => grid.autostoreGridName === e.target.value
              );

              dispatch(
                setSelectedAutostoreGridId(
                  selectedGrid?.autostoreGridId || null
                )
              );
            }}
            variant="standard"
          >
            <MenuItem key="All Grids" value="All Grids">
              {`All Grids`}
            </MenuItem>
            {(autostoreGrids || []).map((grid) => (
              <MenuItem
                key={grid.autostoreGridName}
                value={grid.autostoreGridName}
              >
                {`${grid.autostoreGridName}`}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
      <Box sx={{ maxHeight: "calc(100vh - 200px)" }}>
        {[0, 1].includes(tabIndex) && <InventoryTable />}
        {[2, 3].includes(tabIndex) && <InventorySummary />}
      </Box>
      <Box>
        <Box role="tabpanel" hidden={tabIndex != 0} aria-labelledby="tab-pick">
          <PickTab />
        </Box>
        <Box
          role="tabpanel"
          hidden={tabIndex != 1}
          aria-labelledby="tab-induct"
        >
          <InductionTab />
        </Box>
        <Box
          role="tabpanel"
          hidden={tabIndex != 2}
          aria-labelledby="tab-order-generate"
        >
          <GenerateOrdersPage />
        </Box>
        <Box
          role="tabpanel"
          hidden={tabIndex != 3}
          aria-labelledby="tab-putaway-tasks-generate"
        >
          <GeneratePutAwayTasksPage />
        </Box>
      </Box>
    </Box>
  );
}
