import { Stack, Tab, Tabs, useTheme } from "@mui/material";

import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { WorkstationConfig } from "~/features/workstationConfig/WorkstationConfig";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";

import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectIfWorkstationsTabShouldBeEnabled } from "~/redux/selectors/workstationsSelectors";

import FCReference from "./FCReference";
import { GridSettings } from "./GridSettings";
import { Settings } from "./Settings";
import { BatchSettings } from "./batchSettings/BatchSettings";

type SettingsViewProps = { viewTitle?: ViewNameTranslation };

function SettingsView(props: SettingsViewProps) {
  const { viewTitle } = props;

  const { t } = useTranslation();
  const shouldEnableWorkstationTab = useAppSelector(
    selectIfWorkstationsTabShouldBeEnabled
  );
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const { palette } = useTheme();

  useNavbar({ viewTitle });

  const validTabs = [
    "settings",
    ...(shouldEnableWorkstationTab ? ["workstation-config"] : []),
    ...(isAdmin ? ["grid-settings", "batch-settings"] : [])
  ];

  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "settings",
    validTabs,
    key: "settings-tab"
  });

  return (
    <Box>
      <Stack
        position="sticky"
        width="100%"
        bgcolor={palette.background.default}
        top={0}
        // z index is set on form controls within the page to 1, so set this
        // to 1 to avoid the controls rendering over top of this sticky panel
        zIndex="1"
      >
        <FCReference mt={3} ml={3} />

        <Tabs
          value={tabIndex}
          onChange={(_event, newValue) =>
            handleTabIndexChange(newValue as string)
          }
          aria-label="settings tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ ml: 3 }}
        >
          <Tab label={t("settings")} value="settings" />
          {shouldEnableWorkstationTab && (
            <Tab label={t("workstations")} value="workstation-config" />
          )}

          {isAdmin && (
            <Tab
              label={`${t("batch")} ${t("nav.viewname.settings")}`}
              value="batch-settings"
            />
          )}
          {isAdmin && <Tab label={t("grid settings")} value="grid-settings" />}
        </Tabs>
      </Stack>
      <Box>
        {tabIndex === "settings" && <Settings />}
        {tabIndex === "workstation-config" && shouldEnableWorkstationTab && (
          <WorkstationConfig />
        )}
        {isAdmin && (
          <>
            {tabIndex === "batch-settings" && <BatchSettings />}
            {tabIndex === "grid-settings" && <GridSettings />}
          </>
        )}
      </Box>
    </Box>
  );
}

export default SettingsView;
