import { ManualPick20Px, R5Robot20Px } from "@locaisolutions/icons";
import { Box, Typography, useMediaQuery, Stack, useTheme } from "@mui/material";
import {
  autostoreTheme,
  formatUtcDate,
  useToast,
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowSkeleton,
  ASTableV2RowEmpty
} from "@qubit/autoparts";
import { t } from "i18next";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import {
  inventoryDateLabel,
  generateLocationNameFromInventorySummary,
  checkIsExpiration,
  HoldTypeSource
} from "~/lib/helpers";
import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import { GetPortResponse } from "~/redux/actions";
import { clearSelectedVariant } from "~/redux/actions/inventory";
import { selectIsAdjustingBins } from "~/redux/selectors/inventorySelectors";
import { useLazyGetBinQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { InventorySummaryDto, HoldDto } from "~/types/api";

import {
  setAllInventoryPage,
  setAdjustingInventoryPage,
  setSelectedSummaries,
  setSelectedAdjustingSummary,
  appendSummarySelector
} from "./inventory.slice";
import { useViewType } from "./useViewType";

interface InventoryTablesProps {
  summariesToDisplay: InventorySummaryDto[];
  portStateByPortArray: {
    getPortResponse: GetPortResponse;
    timestamp: Date;
  }[];
  selectedAutostoreGridId?: Guid;
}

export const limit = 5;

export const InventoryTables = (props: InventoryTablesProps) => {
  const { summariesToDisplay, portStateByPortArray, selectedAutostoreGridId } =
    props;
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const isMobile = useMediaQuery(autostoreTheme.breakpoints.down("sm"));
  const trackedPageName = useMixpanelPageName();

  const { isBinView, isProductsView } = useViewType();
  const isAdjustingBins = useAppSelector(selectIsAdjustingBins);
  const loadingInventorySummaries = useAppSelector(
    (state) => state.inventory.loadingInventorySummaries
  );
  const inv_inventoryDateLabel = useAppSelector(
    (state) => state.site.clientConfig.inv_inventoryDateLabel
  );
  const inventorySummaries = useAppSelector(
    (state) => state.inventory.inventorySummaries
  );
  const portStateBinIds = portStateByPortArray.map(
    (port) => port.getPortResponse.selectedBin
  );
  const inventorySummariesCount = useAppSelector(
    (state) => state.inventory.inventorySummariesCount
  );
  const allInventoryPage = useAppSelector(
    (state) => state.inventoryNew.allInventoryPage
  );
  const adjustingInventoryPage = useAppSelector(
    (state) => state.inventoryNew.adjustingInventoryPage
  );
  const selectedAdjustingSummary = useAppSelector(
    (state) => state.inventoryNew.selectedAdjustingSummary
  );
  const selectedSummaries = useAppSelector(
    (state) => state.inventoryNew.selectedSummaries
  );
  const workstationPortIds = useAppSelector(
    (state) => state.workstations.siteAllPortIds
  );
  const selectedSummariesAtPort = selectedSummaries
    .filter((sum) => portStateBinIds.includes(sum.autostoreBinNumber || 0))
    .map((sum) => sum.inventoryId);
  const selectedRows = selectedSummaries.map((sum) => sum.inventoryId);

  const { warningToast, errorToast } = useToast();

  // RTKQ
  const [fetchBinInfo] = useLazyGetBinQuery();

  // if there's only one row, auto-select the row
  useEffect(() => {
    if (isAdjustingBins && selectedSummaries.length === 1) {
      dispatch(setSelectedAdjustingSummary(selectedSummaries[0]));
    }
  }, [dispatch, isAdjustingBins, selectedSummaries, selectedSummariesAtPort]);

  const fetchBinInfoCallback = (binNumber: number): Promise<boolean> => {
    if (!selectedAutostoreGridId) return Promise.resolve(false);

    return fetchBinInfo({
      autostoreGridId: selectedAutostoreGridId,
      binNumber: binNumber
    })
      .unwrap()
      .then((binStatus) => {
        if (
          binStatus.binState.binMode === "O" &&
          !workstationPortIds.includes(binStatus.binState.portId)
        ) {
          warningToast(`${binNumber} is at a different port`);
          return true;
        }

        return false; // Resolve the promise with the boolean value
      })
      .catch(() => {
        errorToast(`Failed to query bin ${binNumber}`);
        return false; // Resolve the promise with false in case of error
      });
  };

  const onBinRowClick = async (row: InventorySummaryDto) => {
    const rowId = row.inventoryId;
    if (selectedRows.includes(rowId)) {
      dispatch(
        setSelectedSummaries(
          selectedSummaries.filter((summary) => summary.inventoryId !== rowId)
        )
      );
      if (selectedRows.length === 1 && (isBinView || isProductsView)) {
        dispatch(clearSelectedVariant());
      }
      mixpanelTrack({
        trackedPageName,
        type: "Unselect Row",
        label: "Unselect Inventory Row",
        eventProperties: row
      });
    } else {
      const selectedInventorySummary = inventorySummaries.find(
        (summary) => summary.inventoryId === rowId
      );
      mixpanelTrack({
        trackedPageName,
        type: "Select Row",
        label: "Select Inventory Row",
        eventProperties: row
      });
      if (selectedInventorySummary?.autostoreBinNumber) {
        // if select inventory bin is at another port don't add the inventory to the selectedRows.
        const isBinAtAnotherPort = await fetchBinInfoCallback(
          selectedInventorySummary.autostoreBinNumber
        );
        if (isBinAtAnotherPort) return;
      }
      dispatch(appendSummarySelector(row));
    }
  };

  const summariesPagination: Parameters<typeof ASTableV2>[0]["pagination"] = {
    page: allInventoryPage,
    pageCount: inventorySummariesCount
      ? Math.ceil(inventorySummariesCount / limit)
      : 0,
    setPage: (p) => {
      dispatch(setAllInventoryPage(p));
    }
  };

  const adjustingBinsPagination: Parameters<typeof ASTableV2>[0]["pagination"] =
    {
      page: adjustingInventoryPage,
      pageCount: Math.ceil(selectedSummaries.length / limit),
      setPage: (p) => {
        dispatch(setAdjustingInventoryPage(p));
      }
    };

  const isLoading = !isAdjustingBins && loadingInventorySummaries;
  const rowData = isAdjustingBins ? summariesToDisplay : inventorySummaries;
  return (
    <ASTableV2
      gridTemplateColumns={`28px ${isAdjustingBins ? "" : "1fr"} repeat(${isMobile ? 3 : 4}, auto)`}
      gridTemplateRows="50px repeat(5, 1fr)"
      isLoading={false}
      pagination={
        isAdjustingBins ? adjustingBinsPagination : summariesPagination
      }
    >
      <ASTableV2Header>
        <ASTableV2Row>
          <ASTableV2Cell />
          {!isAdjustingBins && (
            <ASTableV2Cell>
              <Typography variant="tableBody">{t("product")}</Typography>
            </ASTableV2Cell>
          )}
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("bin")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("quantity")}</Typography>
          </ASTableV2Cell>
          {!isMobile && (
            <ASTableV2Cell>
              <Typography variant="tableBody">{t("hold type")}</Typography>
            </ASTableV2Cell>
          )}
          <ASTableV2Cell>
            <Typography variant="tableBody">
              {t(inventoryDateLabel(inv_inventoryDateLabel))}
            </Typography>
          </ASTableV2Cell>
        </ASTableV2Row>
      </ASTableV2Header>
      <ASTableV2Body>
        {isLoading && (
          <ASTableV2RowSkeleton
            rows={limit}
            columns={!isAdjustingBins ? 6 : 5}
          />
        )}
        {!isLoading && !rowData.length && (
          <ASTableV2RowEmpty>{t("no inventory found")}</ASTableV2RowEmpty>
        )}
        {!isLoading &&
          rowData.map((row) => (
            <ASTableV2Row
              key={row.inventoryId}
              selected={
                isAdjustingBins
                  ? selectedAdjustingSummary?.inventoryId === row.inventoryId
                  : selectedRows.includes(row.inventoryId)
              }
              highlighted={
                isAdjustingBins &&
                selectedSummariesAtPort.includes(row.inventoryId)
              }
              onClick={() => {
                if (isAdjustingBins) {
                  dispatch(setSelectedAdjustingSummary(row));
                  mixpanelTrack({
                    trackedPageName,
                    type: "Select Row",
                    label: "Select Inventory Row To Adjust",
                    eventProperties: row
                  });
                  return;
                } else {
                  return onBinRowClick(row);
                }
              }}
              sx={{
                "> td:first-of-type": {
                  borderColor: selectedRows.includes(row.inventoryId)
                    ? undefined
                    : row.temperatureZone?.toLowerCase() + ".main",
                  backgroundColor: row.temperatureZone?.toLowerCase() + ".main"
                }
              }}
            >
              <ASTableV2Cell />
              {!isAdjustingBins && (
                <ASTableV2Cell>
                  <div>
                    <Typography variant="tableBody">
                      {row.productName}
                    </Typography>
                    <Stack
                      sx={{ marginTop: 1, textTransform: "capitalize" }}
                      direction="row"
                      spacing={1}
                    >
                      {row.binType === "autostore" ? (
                        <R5Robot20Px style={{ fill: palette.text.secondary }} />
                      ) : (
                        <ManualPick20Px
                          style={{ fill: palette.text.secondary }}
                        />
                      )}
                      <Typography variant="caption" color="text.secondary">
                        {row.binType === "autostore"
                          ? t("autostore")
                          : row.binType}
                      </Typography>
                    </Stack>
                  </div>
                </ASTableV2Cell>
              )}
              <ASTableV2Cell
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center"
                }}
              >
                <Typography variant="tableBody">
                  {row.binType === "autostore"
                    ? `${row.autostoreBinNumber || ""}`
                    : generateLocationNameFromInventorySummary(row)}
                </Typography>
                <Typography variant="tableBody">
                  {`${t("compartment")}: ${row.autostoreCompartmentNumber}`}
                </Typography>
              </ASTableV2Cell>
              <ASTableV2Cell>
                <div>
                  <Typography variant="tableBody">
                    {`${row.count?.value || t("n/a")} ${row.count?.units || ""}`}
                  </Typography>
                </div>
              </ASTableV2Cell>
              {!isMobile && (
                <ASTableV2Cell>
                  <Box>
                    {row.holds?.map((hold: HoldDto) => {
                      const sourceText = t(
                        hold.source.toLowerCase() as HoldTypeSource
                      );
                      return (
                        <Box key={hold.reasonCode}>
                          <Typography variant="tableBody">{`${t(
                            hold.reasonCode as HoldTypeSource
                          )} (${sourceText})`}</Typography>
                        </Box>
                      );
                    }) || ""}
                  </Box>
                </ASTableV2Cell>
              )}
              <ASTableV2Cell>
                <Typography variant="tableBody">
                  {formatUtcDate(
                    checkIsExpiration(inv_inventoryDateLabel)
                      ? row.expiration
                      : row.manufactureDate
                  )}
                </Typography>
              </ASTableV2Cell>
            </ASTableV2Row>
          ))}
      </ASTableV2Body>
    </ASTableV2>
  );
};
