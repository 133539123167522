import { ErrorBoundary, Shell } from "@qubit/autoparts";
import { Outlet } from "react-router";

import { Navbar } from "~/features/navbar/Navbar";
import { NavigationDrawer } from "~/features/navigationDrawer/NavigationDrawer";

export function BasicLayout() {
  return (
    <Shell AppBar={<Navbar />} Drawer={<NavigationDrawer />}>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Shell>
  );
}
