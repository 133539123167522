import { Box, Chip } from "@mui/material";
import { BoxProps } from "@mui/system";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

const FCReference = (props: BoxProps) => {
  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );
  const { t } = useTranslation();
  const isFCSelected = !!selectedFulfillmentCenter;
  const fcName = isFCSelected
    ? selectedFulfillmentCenter.name
    : t("no fc selected");

  return (
    <Box {...props}>
      <Chip
        label={fcName}
        sx={{
          backgroundColor: isFCSelected
            ? "rgb(39, 111, 236, 0.1)"
            : "rgb(225, 27, 34, 0.1)",
          color: isFCSelected ? "primary.main" : "error.main",
          borderRadius: "8px",
          padding: "0px 15px"
        }}
      />
    </Box>
  );
};

export default FCReference;
