import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  ButtonGroup,
  ButtonGroupProps,
  MenuItem,
  useMediaQuery,
  Box,
  AppBar,
  Button,
  Divider,
  IconButton,
  Menu,
  Toolbar,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { mobileWidth, globalDateFormat, DatePicker } from "@qubit/autoparts";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import { useAppSelector } from "~/app/store";

import UserProfileMenu from "~/features/userProfileMenu/UserProfileMenu";
import { NavbarContext } from "~/hooks/useNavbar";

import { selectIsUserLoggedIn } from "~/redux/selectors/authSelectors";

import { BurgerButton } from "./BurgerButton";

import { WorkstationInformation } from "./WorkstationInformation";
import "~/assets/navbar.css";

export const drawerWidth = "216px";

const StyledButtonGroup = styled(ButtonGroup)({
  justifyContent: "center",
  width: "100%",
  color: "white",
  gap: "6px",
  "& .MuiButton-root": {
    borderColor: "white"
  }
});

export function NavBarButtonGroup(props: ButtonGroupProps) {
  return (
    <StyledButtonGroup variant="text" aria-label="text primary button group">
      {props.children}
    </StyledButtonGroup>
  );
}

export function UnselectButton({ onClick }: { onClick: () => void }) {
  const isMobile = useMediaQuery(mobileWidth);

  return (
    <Button
      aria-label="clear selection"
      onClick={onClick}
      sx={{
        position: "absolute",
        left: 0,
        justifyContent: isMobile ? "left" : "center",
        zIndex: 1001
      }}
    >
      <ClearIcon style={{ color: "white" }} />
    </Button>
  );
}

const StyledTitle = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block"
  }
}));

const StyledSectionDesktop = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}));

export type MenuItem = {
  textContent: string;
  testId?: string;
  actionCb: () => void | Promise<void>;
};

type Props = {
  onDevCheatClick?: () => void;
};

export function Navbar({ onDevCheatClick }: Props) {
  const {
    centerComponent,
    datePicker,
    informationalMenuItems,
    isHidden,
    menuItems,
    rightComponent,
    toolbar,
    viewTitle
  } = useContext(NavbarContext);

  const isMobile = useMediaQuery(mobileWidth);
  const { t } = useTranslation();
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);

  /** Refs allowing CSSTransitions a direct reference to their children.
   * Required to avoid a React warning about findDomNode.
   */
  const transitionRefs = {
    isToolbarActive: useRef(null),
    primaryNavBar: useRef(null),
    viewName: useRef(null),
    centerComponent: useRef(null),
    datePicker: useRef(null),
    mobileMore: useRef(null),
    rightComponent: useRef(null),
    moreMenu: useRef(null)
  };

  const isToolbarActive = !!toolbar;

  const bgColor = isToolbarActive ? "primary.main" : "gray.light";
  const contrastColor = "darkGray.dark";

  const [datepickerIsOpen, setDatepickerIsOpen] = useState(false);

  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const showInformationalMenuItems =
    informationalMenuItems && informationalMenuItems?.length > 0;
  let viewName = "";

  if (viewTitle) {
    viewName = t(viewTitle);
  }

  if (isMobile) {
    if (datePicker) {
      menuItems.push({
        textContent: moment(datePicker.selectedDate).format(globalDateFormat),
        actionCb: () => setDatepickerIsOpen(true)
      });
    }
  }

  return isHidden ? (
    <></>
  ) : (
    <>
      <AppBar
        data-testid="navbar"
        id="navbar-appbar"
        position="fixed"
        sx={{
          bgcolor: bgColor,
          displayPrint: "none",
          transition: "background-color 300ms",
          zIndex: 1201
        }}
      >
        {toolbar && (
          <CSSTransition
            nodeRef={transitionRefs.isToolbarActive}
            in={isToolbarActive}
            timeout={300}
            unmountOnExit
            classNames={{
              appearActive: "fadeAppear",
              enterActive: "fadeAppear",
              exitActive: "fadeExit"
            }}
            data-testid="context-children"
          >
            <div ref={transitionRefs.isToolbarActive}>{toolbar}</div>
          </CSSTransition>
        )}

        <CSSTransition
          nodeRef={transitionRefs.primaryNavBar}
          in={!isToolbarActive}
          timeout={300}
          unmountOnExit
          classNames={{
            appearActive: "fadeAppear",
            enterActive: "fadeAppear",
            exitActive: "fadeExit"
          }}
        >
          {/* primary nav bar */}
          <Toolbar
            ref={transitionRefs.primaryNavBar}
            data-testid="navbar-toolbar-primary"
          >
            <BurgerButton />

            {/* view name */}

            <CSSTransition
              nodeRef={transitionRefs.viewName}
              in={!isToolbarActive}
              timeout={300}
              unmountOnExit
              classNames={{
                appearActive: "flyLeftAppear",
                enterActive: "flyLeftAppear",
                exitActive: "flyLeftExit"
              }}
            >
              <StyledTitle
                ref={transitionRefs.viewName}
                id="navbar-view-name"
                onClick={() => {
                  if (onDevCheatClick) {
                    onDevCheatClick();
                  }
                }}
              >
                {/* view name display */}
                <Typography
                  sx={{
                    display: "flex",
                    color: contrastColor,
                    marginLeft: "10px"
                  }}
                  noWrap
                  variant="body1"
                >
                  {viewName}
                </Typography>
              </StyledTitle>
            </CSSTransition>

            <div style={{ flexGrow: 1 }} />
            {/* primary controls such as search or filter */}
            <CSSTransition
              nodeRef={transitionRefs.centerComponent}
              in={!!centerComponent}
              timeout={300}
              unmountOnExit
              classNames={{
                appearActive: "fadeAppear",
                enterActive: "fadeAppear",
                exitActive: "fadeExit"
              }}
            >
              <Box
                ref={transitionRefs.centerComponent}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  maxWidth: 800
                }}
              >
                {centerComponent}
              </Box>
            </CSSTransition>

            <div style={{ flexGrow: 1 }} />
            <StyledSectionDesktop id="section-desktop">
              {/* datepicker button */}
              {!!datePicker && (
                <CSSTransition
                  nodeRef={transitionRefs.datePicker}
                  in={!isToolbarActive}
                  timeout={300}
                  unmountOnExit
                  classNames={{
                    appearActive: "flyRightAppear",
                    enterActive: "flyRightAppear",
                    exitActive: "flyRightExit"
                  }}
                >
                  <IconButton
                    ref={transitionRefs.datePicker}
                    sx={{ color: contrastColor }}
                    onClick={(): void => setDatepickerIsOpen(true)}
                    edge="end"
                    size="large"
                    aria-label="choose a date"
                  >
                    <CalendarTodayIcon />

                    {/* date within the icon */}
                    <Box
                      style={{
                        position: "absolute",
                        marginTop: 5,
                        marginLeft: 1,
                        width: 24,
                        height: 24,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography sx={{ fontSize: "0.6rem" }}>
                        {datePicker.selectedDate
                          ? moment(datePicker.selectedDate).format("D")
                          : ""}
                      </Typography>
                    </Box>
                  </IconButton>
                </CSSTransition>
              )}
            </StyledSectionDesktop>
            <WorkstationInformation />
            {isUserLoggedIn && <UserProfileMenu />}
            <div id="section-mobile" style={{ display: "flex" }}>
              {menuItems.length > 0 && (
                <CSSTransition
                  nodeRef={transitionRefs.mobileMore}
                  in={!isToolbarActive}
                  timeout={300}
                  unmountOnExit
                  classNames={{
                    appearActive: "flyRightAppear",
                    enterActive: "flyRightAppear",
                    exitActive: "flyRightExit"
                  }}
                >
                  {/* more menu button */}
                  <IconButton
                    ref={transitionRefs.mobileMore}
                    id="navbar-more-menu-button"
                    sx={{ color: contrastColor }}
                    onClick={(e): void => setMoreMenuAnchorEl(e.currentTarget)}
                    edge="end"
                    size="large"
                    aria-label="more"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </CSSTransition>
              )}
            </div>

            {!!rightComponent && (
              <div style={{ display: "flex" }}>
                <CSSTransition
                  nodeRef={transitionRefs.rightComponent}
                  in={!isToolbarActive}
                  timeout={300}
                  unmountOnExit
                  classNames={{
                    appearActive: "flyRightAppear",
                    enterActive: "flyRightAppear",
                    exitActive: "flyRightExit"
                  }}
                >
                  <div ref={transitionRefs.rightComponent}>
                    {rightComponent}
                  </div>
                </CSSTransition>
              </div>
            )}

            {/* datepicker modal / component */}
            {!!datePicker && (
              <DatePicker
                isOpen={datepickerIsOpen}
                setIsOpenCb={(isOpen) => setDatepickerIsOpen(isOpen)}
                onDateChange={(date: Date | null): void => {
                  datePicker.onDateChange(date);
                }}
                selectedDate={datePicker.selectedDate}
              />
            )}

            {/* more menu popup */}
            {menuItems.length > 0 && (
              <CSSTransition
                nodeRef={transitionRefs.moreMenu}
                in={!isToolbarActive}
                timeout={300}
                unmountOnExit
                classNames={{
                  appearActive: "flyRightAppear",
                  enterActive: "flyRightAppear",
                  exitActive: "flyRightExit"
                }}
              >
                <Menu
                  ref={transitionRefs.moreMenu}
                  id="navbar-more-menu"
                  anchorEl={moreMenuAnchorEl}
                  keepMounted
                  open={!!moreMenuAnchorEl}
                  onClose={() => setMoreMenuAnchorEl(null)}
                >
                  {/* more menu items */}
                  {menuItems.map((menuItem) => (
                    <MenuItem
                      key={`more-menu-${menuItem.textContent}`}
                      data-testid={menuItem.testId || null}
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                        menuItem.actionCb();
                        setMoreMenuAnchorEl(null);
                      }}
                    >
                      {menuItem.textContent}
                    </MenuItem>
                  ))}
                  {showInformationalMenuItems && <Divider />}
                  {showInformationalMenuItems &&
                    informationalMenuItems.map((menuItem) => (
                      <MenuItem
                        key={`more-menu-${menuItem.textContent}`}
                        style={{ userSelect: "text" }}
                      >
                        {menuItem.textContent}
                      </MenuItem>
                    ))}
                </Menu>
              </CSSTransition>
            )}
          </Toolbar>
        </CSSTransition>
      </AppBar>
    </>
  );
}
