import { Box, Paper, Stack, Typography } from "@mui/material";
import {
  ProgressButton,
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2RowCollapse,
  ASTableV2RowEmpty
} from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { t } from "i18next";
import { Fragment } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { groupBy } from "~/lib/shared";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import {
  useDeleteTaskGroupMutation,
  useDeleteTaskMutation,
  useGetAutostoreTasksQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { AutostoreTaskGroupInfoExtended } from "~/types/api";

import { setSelectedTaskGroupId } from "./status.slice";

const Row = ({ row }: { row: AutostoreTaskGroupInfoExtended }) => {
  const selectedGridId = useAppSelector(selectStatusSelectedAutostoreGridId);
  const selected = useAppSelector(
    (state) => state.status.selectedTaskGroupId === row.taskGroupId
  );
  const dispatch = useAppDispatch();
  const [cancelTaskGroup] = useDeleteTaskGroupMutation();
  const [cancelTask] = useDeleteTaskMutation();

  return (
    <Fragment key={row.taskGroupId}>
      <ASTableV2Row
        expanded={selected}
        onClick={() =>
          dispatch(
            setSelectedTaskGroupId(selected ? undefined : row.taskGroupId)
          )
        }
      >
        <ASTableV2Cell>
          <Typography variant="tableBody">{row.taskGroupId || ""}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">
            {row.selectedPortId || ""}
          </Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{row.category || ""}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{row.shipment || ""}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{row.batchName || ""}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{row.batchStatus || ""}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <Typography variant="tableBody">{row.tasks.length || ""}</Typography>
        </ASTableV2Cell>
        <ASTableV2Cell>
          <ProgressButton
            buttonSize="small"
            emphasis="low"
            responsive
            variant="outlined"
            color="primary"
            aria-label={`cancel-task-group-id-${row.taskGroupId}`}
            onClick={async (): Promise<void> => {
              if (!selectedGridId) return;

              await cancelTaskGroup({
                taskGroupId: row.taskGroupId,
                autostoreGridId: selectedGridId
              });
            }}
            style={{
              marginRight: 10
            }}
          >
            {t("cancel task group")}
          </ProgressButton>
        </ASTableV2Cell>
      </ASTableV2Row>
      <ASTableV2RowCollapse expanded={selected}>
        <Stack
          direction="row"
          flexWrap={"wrap"}
          gap={2}
          justifyContent="space-between"
        >
          {Object.entries(groupBy(row.tasks, (t) => `${t.binId}`)).map(
            ([binId, tasks]) => (
              <Paper key={binId} sx={{ p: 2 }}>
                <Typography variant="tableBody">Bin Id: {binId}</Typography>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))"
                  gap={2}
                  my={2}
                >
                  {tasks.map((task) => (
                    <Box
                      key={task.taskId}
                      display="flex"
                      gap={2}
                      alignItems="center"
                    >
                      <Typography variant="tableBody">
                        Task Id: {task.taskId}
                      </Typography>
                      <ProgressButton
                        buttonSize="small"
                        emphasis="high"
                        responsive
                        variant="outlined"
                        color="primary"
                        aria-label={`cancel-task-id-${task.taskId}`}
                        onClick={async (): Promise<void> => {
                          if (!selectedGridId) return;

                          await cancelTask({
                            taskId: task.taskId,
                            autostoreGridId: selectedGridId
                          });
                        }}
                      >
                        {t("cancel task")}
                      </ProgressButton>
                    </Box>
                  ))}
                </Box>
              </Paper>
            )
          )}
        </Stack>
      </ASTableV2RowCollapse>
    </Fragment>
  );
};

export const TaskGroupTableByTaskGroup = () => {
  const selectedGridId = useAppSelector(selectStatusSelectedAutostoreGridId);

  const { data: autostoreTasks, isLoading: autostoreTasksIsLoading } =
    useGetAutostoreTasksQuery(selectedGridId || skipToken);

  return (
    <ASTableV2
      gridTemplateColumns="repeat(8, auto)"
      isLoading={autostoreTasksIsLoading}
    >
      <ASTableV2Header>
        <ASTableV2Row>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("TaskGroupId")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("Port")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("Category")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("Shipment")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("Batch Name")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("Batch Status")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("Tasks")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell></ASTableV2Cell>
        </ASTableV2Row>
      </ASTableV2Header>
      <ASTableV2Body>
        {!autostoreTasksIsLoading && !autostoreTasks?.length && (
          <ASTableV2RowEmpty>{t("no task groups found")}</ASTableV2RowEmpty>
        )}
        {(autostoreTasks || []).map((row) => (
          <Row key={row.taskGroupId} row={row} />
        ))}
      </ASTableV2Body>
    </ASTableV2>
  );
};
