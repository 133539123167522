import { Box } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";

type Props = { upc: string };

/**
 * Renders a QR encoded UPC on the ProductCard.
 */
export function QRCode({ upc }: Props) {
  return (
    <Box>
      <Box position="absolute" right={0} zIndex={1}>
        <QRCodeCanvas value={upc} size={100} includeMargin={false} />
      </Box>
    </Box>
  );
}
